.grid {
  margin-right: -20px;
  margin-left: -20px;
  margin-top: 0;
}


.grid > .col, .grid > [class*=col] {
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}
