form {
  label {
    font-weight: normal;
    font-size: 1.4rem;
    margin-bottom: 10px;
    display: inline-block;
  }
  .form-title {
    margin: 1rem 0;
    font-weight: bold !important;
    display: block;
    position: relative;
    @include title-with-green-bar();
  }
  .date-field{
    display: inline-block;
    width: 100%;
    label{
      width: 100%;
    }
  }
  .p-field > small, .helptext{
    font-size: 1.2rem;
    font-style: italic;

  }

  .p-field {
    margin-bottom: 2rem;

    input,
    .p-calendar,
    .p-dropdown,
    .p-autocomplete,
    .p-inputnumber,
    .p-calendar,
    .p-multiselect,
    .p-calendar {
      width: 100%;
    }
  }
  .p-field-mini {
    margin-bottom: 1rem;
  }
}
.p-field-submit {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 30px;
  a {
    margin-right: 20px;
    cursor: pointer;
    font-size: 1.6rem;
  }
  &.nomarge {
    margin: 0;
  }
  &.margebottom{
    margin-bottom: 20px;
    margin-top: 0;
  }
  button + button {
    margin-left: 20px;
  }
}


.p-field-checkbox,
.p-field-radiobutton {
  margin-bottom: 30px;
  > label {
    margin-left: 0;
  }
}

.p-field-radiobutton-mini{
  margin-bottom: 10px;
}

.p-colorpicker {
  display: block !important;
  input.p-colorpicker-preview {
    width: 40px;
  }
}

.p-formgroup-inline-action{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  p-dropdown{
    width: calc(100% - 45px);
    display: inline-block;
  }
  p-button{
    display: inline-block;

  }
  button.p-button.button__icon{
    height: 40px;
    border: none;
    width: 40px;
    border-radius: 5px;
    text-align: center;
    margin-left: 0;

    .p-button-label{
      display: none;
    }
    .pi{
      font-size: 15px;
      left: 0px;
    }
  }

}



.p-radiobutton {
  width: 16px;
  height: 16px;
  &-label {
    margin-bottom: 0;
    padding-left: 10px;
    margin-left: 0;
  }
  .p-radiobutton-box {
    .p-radiobutton-icon {
      width: 10px;
      height: 10px;
    }

    width: 16px;
    height: 16px;

    &.p-highlight {
      border-color: $primaryGreenColor;
      background: $primaryGreenColor;
    }
  }
}


