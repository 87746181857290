.header-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__text{
    font-size: 1.2rem;
    h1{
      font-size: 2.2rem;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
}


.avatar {
  background-color: #D6F6F2;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  color: $darkBlueGreenColor;
  line-height: 30px;
  font-size: 1.15rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
}
.header .action{
   &:hover{
    cursor: pointer;
  }
  .avatar{
    margin-right: 10px;
    span{
      position: relative;
      top: -1px;
      margin: 0 auto;
      width: 30px;
      display: block;
      text-align: center;
    }
    &:hover{
      cursor: pointer;
    }
  }
  a{
    color: $darkBlueGreenColor;
    text-decoration: underline;
  }
  i{
    margin-left: 10px;
    font-size: 11px;
    color:$lightdarkGrayColor;
  }
}
