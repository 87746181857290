.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

.avatar {
  width: 65px;
  height: 65px;
  background-color: $primaryGreenColor;
  border-radius: 50%;
  color: $whiteColor;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 4rem;
}

.cursorPointerType {
  cursor: pointer;
}

.vh-100 {
  height: 100dvh;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45%;
}

.w-25 {
  width: 25%;
}
