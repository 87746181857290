.slide-items{
  margin: 2px 0;
}
.slider-wrapper{
  margin-top: 5px;
  .tns-outer{
    .tns-nav{
      display:none;
    }
  }
}

.spinner-icon{
  min-height: 20px;
}

.week-container{
  display: flex;
  justify-content: space-between;
  .day-container{

  }
}
