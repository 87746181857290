@mixin green-bar {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 40px;
    border-radius: 5px;
    background-color: $primaryGreenColor;
}

@mixin title-with-green-bar {
    position: relative;
    font-weight: normal;
    font-size: 2.6rem;
    margin-bottom: 30px;
    margin-top: 0;
    padding-bottom: 7px;
    color: $darkGrayColor;
    min-height: 31px;
    
    i {
        margin-left: 10px;
        cursor: pointer;
        font-size: 15px;
        color: $purpleColor;
        vertical-align: middle;
    }

    &::before {
        @include green-bar();
    }
 
}

