.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 0;
  height: 100dvh;
}

.p-sidebar {
  border: none;
  padding: 0;
  box-shadow: none;
  color: $darkGrayColor;
  position: absolute;
  min-height: 100dvh;
  height: auto !important;
  .close {
    font-size: 1.4rem;
    color: $lightLiGrayGrayColor;
    width: 70px;
    cursor: pointer;
    border-left: 1px solid $lightLiGrayGrayColor;
    line-height: normal;
    text-align: center;
    position: relative;
    top: -20px;
    height: calc(100% + 40px);
    text-align: center;
    right: -20px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
    }
    &:hover {
      border-left: 1px solid darken($lightLiGrayGrayColor, 10%);
      color: darken($lightLiGrayGrayColor, 10%);
    }
  }

  .tooltip {
    font-size: 1.4rem;
    color: $darkGrayColor;
    width: 50px;
    height: 70px;
    cursor: pointer;
    position: absolute;
    right: 170px;
    top: 0;

    line-height: 70px;
    text-align: center;
    i {
      font-size: 20px;
    }
    &:hover {
      color: darken($darkGrayColor, 10%);
    }
  }

  &.p-sidebar-left.p-sidebar-lg,
  &.p-sidebar-right.p-sidebar-lg {
    width: 95vw;
  }
  &--content {
    padding: 15px;
    overflow-x: hidden;
    height: auto;

    .intention img{
      width: 14px;
      height: auto;
      vertical-align: middle;
      display: inline-block;
      margin-left: 5px;
  }
    ul.answer {
      margin-bottom: 20px;
      li {
        color: $lightdarkGrayColor;
        padding-bottom: 10px;
        padding-left: 20px;
        &.active {
          color: $primaryGreenColor;
          font-weight: bold;
        }
      }
    }
  }

}
.p-sidebar.scrollable {
  .p-sidebar--content:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
  }
}

