/* You can add global styles to this file, and also import other style files */
@import './assets/styles/base/reset';
@import './assets/styles/base/charts';
@import './assets/styles/base/layout';
@import './assets/styles/base/mixins';
@import './assets/styles/base/utils';
@import './assets/styles/base/grid';
@import './assets/styles/externals/icon';
@import './assets/styles/atomes/alert';
@import './assets/styles/atomes/button';
@import './assets/styles/atomes/p-autocomplete';
@import './assets/styles/atomes/p-tabs';
@import './assets/styles/atomes/p-dropdown';
@import './assets/styles/atomes/text';
@import './assets/styles/atomes/p-calendar';
@import './assets/styles/atomes/p-checkbox';
@import './assets/styles/atomes/p-input-icon';
@import './assets/styles/atomes/p-multiselect';
@import './assets/styles/atomes/p-tooltip';
@import './assets/styles/atomes/easteregg';
@import './assets/styles/atomes/scrollbar';
@import './assets/styles/atomes/timeSlotEvent';
@import './assets/styles/atomes/box';
@import './assets/styles/atomes/p-toast';
@import './assets/styles/atomes/p-fileupload';
@import './assets/styles/atomes/tiny-slider';
@import './assets/styles/atomes/full-calendar';
@import './assets/styles/atomes/event';
@import './assets/styles/molecules/empty';
@import './assets/styles/molecules/p-sidebar';
@import './assets/styles/molecules/app-sleep-diary';
@import './assets/styles/molecules/p-dialog';
@import './assets/styles/molecules/selectAction';
@import './assets/styles/molecules/agenda';
@import './assets/styles/molecules/agenda-event';
@import './assets/styles/molecules/dialogsidebar';
@import './assets/styles/molecules/pwa-install';
@import './assets/styles/atomes/overlaypanel';
@import './assets/styles/atomes/inputs';
@import './assets/styles/atomes/form';
@import './assets/styles/atomes/inputnumber';
@import './assets/styles/atomes/inputswitch';
@import './assets/styles/atomes/loader';
@import './assets/styles/molecules/login';
@import './assets/styles/molecules/header';
