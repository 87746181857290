.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
  padding: 10px;
  color: $darkGrayColor;
  background: transparent;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  font-size:1.6rem;
  width: 100%;
  &:hover{
    background-color: $lightGrayColor;
  }
}
.p-autocomplete{
  width: 100%;
}
