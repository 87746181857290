.font{
  font-size: 2.5rem;
}
input, select, textarea,
input.p-inputtext, .p-inputtext {
  outline: none;
  box-shadow: none;
  background: $whiteColor;
  border: solid 1px $lightLiGrayGrayColor;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  font-size: 1.4rem;
  font-family: $font;

  &:focus, &:enabled:focus {
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    border: solid 1px $lightLiGrayGrayColor;
  }

  &:hover, &:enabled:hover {
    border: solid 1px darken($lightLiGrayGrayColor, 10%);
  }
}

.calendar-input{
  .p-inputtext{
    border: none;
    padding: 0;
    &:enabled:hover{
      border: none;
      box-shadow: none;
    }
  }
}

input[type=checkbox],
input[type=radio] {
  width: auto;
}

input[type=radio] {
  margin-right: 10px;
}

textarea {
  padding: 10px;
}

.p-inputtextarea-resizable {
  height: auto;
}

textarea, .p-inputtextarea {
  height: auto;
  padding: 10px;
}

.p-inputtextarea{
  min-height: 40px !important;
}

/*Input spéciaux*/

input[type="file"] {
  display: block;
  border: none;
  padding: 0;
  height: auto;
}

input[type=password]::-ms-clear,
input[type=password]::-ms-reveal {
  display: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 999;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/*Select*/
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-origin: content-box;
}

select[multiple] {
  background: none;
  padding: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select::-ms-expand {
  display: none;
}

/*Disabled*/
button[disabled], html input[disabled] {
  background-color: lighten($grayPurpleColor, 30%);
}

/*Radio ou checkbox*/

[type=radio] {
  width: auto;
  height: auto;

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;

    & ~ .label-box {
      position: relative;
      padding-left: 30px;
      font-weight: normal;
      cursor: pointer;
      min-height: 22px;
      line-height: 20px;


      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $grayColor;
        background: $whiteColor;
      }

      &:after {
        content: '';
        position: absolute;
      }
    }
  }
}

[type=radio] {
  &:checked,
  &:not(:checked) {
    & ~ .label-box {
      &:before {
        border-radius: 50%;
      }

      &:after {
        top: 4px;
        left: 4px;
        width: 14px;
        height: 14px;
        background-color: $primaryGreenColor;
        border-radius: 50%;
      }
    }
  }

  &:not(:checked) {
    & ~ .label-box {
      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  &:checked {
    & ~ .label-box {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}


[type=radio][disabled] {
  & ~ .label-box {
    color: $grayColor;
    cursor: auto;

    &:after {
      background-color: $grayColor;
    }
  }
}


/*Fin checkbox*/

[type=submit], [type=reset], button, [type=button] {
  border: 0;
  outline: none;
}

/*Angular*/


.input-group.input-search {
  position: relative;

  .quick-search input {
    padding-right: 35px;
  }

  i {
    position: absolute;
    right: 11px;
    top: 9px;
    color: $grayColor;
    z-index: 1;
    font-size: 2rem;
  }
}

.ui-inputtext.ui-autocomplete-input {
  border: none;
  border-radius: 25px;
  background: $whiteColor;
  padding: 0 10px 0 50px;
  box-shadow: 0px 6px 6px #3A3C5012;
  color: $darkGrayColor;
  //@include font-size(1.4);

  &:enabled:focus:not(.ui-state-error) {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.autocomplete__item {
  i {
    display: inline-block;
    width: 30px;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    width: calc(100% - 30px);
    vertical-align: middle;
  }
}

.wrongInput {
  color: #a80000;
}

