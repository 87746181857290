.empty{
    color:$darkBlueGreenColor;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    text-align: center;
    img{
        margin: 0 auto;
        margin-bottom: 30px;
        width: 145px;
    }
    &.text{
        font-style: italic;
        color: rgba($darkGrayColor, 0.5);
        text-align: left;
        font-weight: normal;
        font-size: 1.4rem;
    }
    &--top{
        &.text{
            top: auto;
            transform: none;
            font-size: 1.4rem;

        }
        &.image{
          margin-top: 30px;
          top: auto !important;
          transform: none !important;
          font-size: 1.4rem;

        }
    }
    p{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    button.p-button.button__primary{
    margin-top: 30px;
    border-radius: 5px;
    align-self: center;
  }
}
.p-accordion-content .empty{
  padding: 10px;
  margin-top: 0;
}
