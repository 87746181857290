.fc .fc-timeGridDay-view .fc-timegrid-slots tbody tr:nth-child(odd) td.fc-timegrid-slot-lane:nth-child(even) {
  border-top: 1px dashed var(--fc-border-color);
}

.fc .fc-timeGridDay-view .fc-timegrid-slots tbody tr:nth-child(4n+5), .fc .fc-timeGridDay-view .fc-timegrid-slots tbody tr:first-child{
  td .fc-timegrid-slot-label-frame{
    font-weight: bold;

  }
}
.p-datepicker table td.p-datepicker-today > span.p-highlight{
  background-color:  $darkBlueGreenColor;
}
.fc-day-today{
  background-color: initial !important;
}

.selected-day{
  color: $darkBlueGreenColor;
}

.fc {
  .agenda-entity {
    border: hidden;
  }

  .agenda-unavailability {
    z-index: 1;
    opacity: .2;
    background-image: url(../../images/proNutriConsult/pattern.png);

    .title {
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 10px;
      padding: 10px;
      position: relative;
      z-index: 999;
      color: black;
    }
  }

  .agenda-consultation {
    z-index: 2;
  }

  .fc-timegrid-col-events {
    width: 100%;
  }

  .fc-scroller {
    padding-top: 5px
  }

  .fc-scrollgrid-section-header {
    display: none;
  }

  .fc-scrollgrid {
    border: hidden;
    tr{
      td{
        border: hidden;
      }
    }
  }

  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc-timegrid-slot {
    border: hidden;
  }

  .fc-timegrid-slots {
    tr {
      height: 30px;
      .fc-timegrid-slot-label {

        div {
          margin-top: -25px;
          padding-right: 10px;
        }
      }
    }

    tr:nth-child(odd) {
      .fc-timegrid-slot-lane{
        border: 1px solid var(--fc-border-color);
      }
    }

    tr:nth-child(even) {
      .fc-timegrid-slot-lane{
        border: 1px dashed var(--fc-border-color);
      }
    }
  }
}
