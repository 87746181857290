.selectAction {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
 /* padding-left: 15px;*/
  align-content: center;
  p-checkbox {
    flex: 1 1 auto;
    text-align: left;
    padding: 5px 0px;
    label{
      font-size: 1rem;
    }
  }
  a.p-button.button__add,
  button.p-button.button__add {
    margin-bottom: 0;
  }
}
