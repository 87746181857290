.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100dvh;
  width: 100%;
  background: linear-gradient(180deg, rgba(8, 154, 169, 1) 0%, rgba(66, 180, 126, 1) 35%, rgba(126, 207, 82, 1) 100%);
  img {
    width: 100px;
    margin-bottom: 10px;
  }
  .logo__title{
    font-size: 3.4rem;
    color: $whiteColor;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top:50px;
    margin-bottom: 200px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &:after {
    content: "";
    height: 250px;
    width: 100%;
    position: absolute;
    bottom: 10px;
    background-image: url(~src/assets/images/proNutriConsult/bulles_login.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.loading__icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.login-wrapper {
  background-color: #f2f7f6;
  padding:3rem;
  height: 100dvh;

  h1 {
    font-size: 4rem;
    color: $darkBlueGreenColor;
    line-height: normal;
    display: block;
    margin-bottom: 0;

    &.psw {
      font-size: 3rem;
      margin-bottom: 50px;
    }
  }
  .login__pwd-forgotten{
    display: block;
    text-align: right;
    color: $darkBlueGreenColor;

  }
  h2 {
    font-size: 1.6rem;
    color: $darkBlueGreenColor;
    line-height: normal;
    display: block;
    margin-bottom: 50px;
  }

  .p-field-submit {
    justify-content: center;
  }

  .button__primary {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
