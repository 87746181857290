.p-input-icon-left, .p-input-icon-right{
  width: 100%;
  > i,  > i{
    font-size: 20px;
    &:first-of-type{
      left: 10px;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }
  > .p-inputtext{
    padding-left: 40px;
  }
}