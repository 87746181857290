@font-face {
  font-family: 'siinapppro';
  src:  url('~src/assets/fonts/siinProApp/siinapppro.eot?x8pcd8');
  src:  url('~src/assets/fonts/siinProApp/siinapppro.eot?x8pcd8#iefix') format('embedded-opentype'),
        url('~src/assets/fonts/siinProApp/siinapppro.ttf?x8pcd8') format('truetype'),
        url('~src/assets/fonts/siinProApp/siinapppro.woff?x8pcd8') format('woff'),
        url('~src/assets/fonts/siinProApp/siinapppro.svg?x8pcd8#siinapppro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'siinapppro' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-settings:before { content: '\e800'; }
.icon-cible:before { content: '\e803'; }
.icon-sleep1:before { content: '\e804'; }
.icon-sleeping:before { content: '\e805'; }
.icon-stethoscope1:before { content: '\e806'; }
.icon-push-pin:before { content: '\e807'; }
.icon-task:before { content: '\e808'; }
.icon-tired:before { content: '\e809'; }
.icon-trash1:before { content: '\e80a'; }
.icon-up_arrow:before { content: '\e80b'; }
.icon-user1:before { content: '\e80c'; }
.icon-wakeup:before { content: '\e80d'; }
.icon-warning:before { content: '\e80e'; }
.icon-bell:before { content: '\e80f'; }
.icon-book:before { content: '\e810'; }
.icon-burger:before { content: '\e811'; }
.icon-stethoscope:before { content: '\e812'; }
.icon-cog-1:before { content: '\e813'; }
.icon-add:before { content: '\e814'; }
.icon-phoneclose:before { content: '\e815'; }
.icon-home:before { content: '\e816'; }
.icon-trash:before { content: '\e817'; }
.icon-expand:before { content: '\e818'; }
.icon-blocked:before { content: '\e819'; }
.icon-loading:before { content: '\e81a'; }
.icon-eyes:before { content: '\e81b'; }
.icon-euro1:before { content: '\e81c'; }
.icon-export-1:before { content: '\e81d'; }
.icon-extinction:before { content: '\e81e'; }
.icon-filter:before { content: '\e81f'; }
.icon-help:before { content: '\e820'; }
.icon-file:before { content: '\e821'; }
.icon-images:before { content: '\e822'; }
.icon-pdf:before { content: '\e823'; }
.icon-insomnia:before { content: '\e824'; }
.icon-left_arrow:before { content: '\e825'; }
.icon-loupe:before { content: '\e826'; }
.icon-patient1:before { content: '\e828'; }
.icon-patient_plus_neg:before { content: '\e829'; }
.icon-patient_plus_pos:before { content: '\e82a'; }
.icon-plus:before { content: '\e82b'; }
.icon-plus_circle:before { content: '\e82c'; }
.icon-print:before { content: '\e82d'; }
.icon-question1:before { content: '\e82e'; }
.icon-recurrent:before { content: '\e82f'; }
.icon-right_arrow:before { content: '\e830'; }
.icon-calendar1:before { content: '\e83a'; }
.icon-calendar_plus:before { content: '\e83b'; }
.icon-camera1:before { content: '\e83c'; }
.icon-nav:before { content: '\e83d'; }
.icon-check1:before { content: '\e83e'; }
.icon-checked:before { content: '\e83f'; }
.icon-clock:before { content: '\e840'; }
.icon-calendar_add:before { content: '\e841'; }
.icon-cross:before { content: '\e842'; }
.icon-down_arrow:before { content: '\e843'; }
.icon-edit1:before { content: '\e844'; }
.icon-erase:before { content: '\e845'; }
.icon-si:before { content: '\e846'; }
.icon-cog-add:before { content: '\e847'; }
.icon-camera_fill:before { content: '\e848'; }
.icon-sv:before { content: '\e849'; }
.icon-maps1:before { content: '\e84a'; }
.icon-navigation:before { content: '\e84b'; }
.icon-mute:before { content: '\e84c'; }
.icon-calendar-mini:before { content: '\e84d'; }
.icon-calendar:before { content: '\e84e'; }
.icon-patient:before { content: '\e84f'; }
.icon-tag-1:before { content: '\e850'; }
.icon-sick:before { content: '\e851'; }
.icon-arrow_left:before { content: '\e852'; }
.icon-arrow_up:before { content: '\e853'; }
.icon-arrow_down:before { content: '\e854'; }
.icon-note:before { content: '\e855'; }
.icon-birthday:before { content: '\e911'; }
.icon-euro-1:before { content: '\e912'; }
.icon-message:before { content: '\e913'; }
.icon-call:before { content: '\e914'; }
.icon-camera:before { content: '\e915'; }
.icon-eat:before { content: '\e916'; }
.icon-minicross:before { content: '\e917'; }
.icon-sport:before { content: '\e918'; }
.icon-download-1:before { content: '\e919'; }
.icon-arrow_right:before { content: '\e91a'; }
.icon-question:before { content: '\e91f'; }
.icon-user-1:before { content: '\e920'; }
.icon-document:before { content: '\e921'; }
.icon-next:before { content: '\e922'; }
.icon-eid:before { content: '\e923'; }
.icon-profilsante:before { content: '\e924'; }
.icon-formulaire:before { content: '\e925'; }
.icon-statistique:before { content: '\e926'; }
.icon-share-apple:before { content: '\e927'; }
.icon-recommandation:before { content: '\e928'; }
.icon-coaching:before { content: '\e929'; }
.icon-down:before { content: '\e92a'; }
.icon-up:before { content: '\e92b'; }
.icon-delete-calendar:before { content: '\e92c'; }
.icon-egal:before { content: '\e92d'; }
.icon-happy:before { content: '\e92e'; }
.icon-check-1:before { content: '\e92f'; }
.icon-edit-1:before { content: '\e930'; }
.icon-search:before { content: '\e931'; }
.icon-sleep:before { content: '\e932'; }
.icon-unmute:before { content: '\e933'; }
.icon-calendar_add_light:before { content: '\e934'; }
.icon-medoc:before { content: '\e935'; }
.icon-homeLine:before { content: '\e936'; }
.icon-down-arrow_light:before { content: '\e937'; }
.icon-up-arrow_light:before { content: '\e938'; }
.icon-end:before { content: '\e939'; }
.icon-external:before { content: '\e93a'; }
.icon-prescription:before { content: '\e93d'; }
