
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,300;1,400&display=swap');


$font: 'Poppins', sans-serif;

$whiteColor: #FFFFFF;
$darkGrayColor: #555555;
$lightdarkGrayColor: #888888;
$lightBCGrayColor: #BCBCBC;
$purpleColor: #53418B;
$lightPurpleColor: #a98dff;
$lightBlueColor: #f2f7f6;
$grayPurpleColor:#BBBABE;

$primaryGreenColor: #A3DD86;
$secondaryGreenColor: #EDF5DA;
$darkGreenColor: rgba(126, 194, 89, 0.79);
$darkBlueGreenColor:#089AA9;
$darkOrangeColor: #ce7509;
$lightBleuGreen:#D6F6F2;
$lightlightBleuGreen:#f4f9f8;

$lightLiGrayGrayColor:#EBEBEB;
$lightLightGrayGrayColor:#FBFBFB;

$veryLightGrayColor: #dfe9ee;
$lightGrayColor: #F6F6F6;
$grayColor: rgb(195, 195, 195);

$lightBorderColor: #F3F4F8;
$borderPurpleColor:#E5E8F2;

$recreativeColor: #61BBE0;

$red:#AB0134;
$redlight:#FF002B;

$pink:#FC5080;

$menuHeight: 80px;
$sideMenuWidth: 90px;
$headerHeight: 40px;
$layoutPaddingX: 20px;
$layoutPaddingY: $layoutPaddingX;

$patientListIconsSize: 50px;

