.timeSlotEvent{
  display: flex;
  flex-direction: column;
  color: $darkGrayColor;
  justify-content: space-between;
  border-radius: 5px;
  background-color: $lightGrayColor;
  .title{
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 10px;
    padding: 10px;

    i{
      margin-left: 5px;
    }

  }
  .localisation{
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    padding: 10px;

    i{
      margin-right: 10px;
    }
    .adress{
      font-size: 1.4rem;
    }
  }
  .color{
    padding: 10px;
  }
}
