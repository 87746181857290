.p-toast {
  opacity: 0.9;
  .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;
      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }
      .p-toast-message-icon {
        font-size: 2rem;
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }
    }

    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #c7d2fe;
      }
    }
    /*Info*/
    &.p-toast-message-info {
      background: #dbe9ff;
      border: solid #429ad4;
      border-width: 0 0 0 6px;
      color: darken(#429ad4, 10%);
      .p-toast-message-icon,
      .p-toast-icon-close {
        color: darken(#429ad4, 10%);
      }
    }
    /*Succes*/
    &.p-toast-message-success {
      background: #f6ffe1;
      border: solid #aee530;
      border-width: 0 0 0 6px;
      color: #aee530;
      color: darken(#aee530, 10%);
      .p-toast-message-icon,
      .p-toast-icon-close {
        color: darken(#aee530, 10%);
      }
    }
    /*warning*/
    &.p-toast-message-warn {
      background: #fff5dd;
      border: solid #fecb55;
      border-width: 0 0 0 6px;
      color: #fecb55;
      color: darken(#fecb55, 10%);
      .p-toast-message-icon,
      .p-toast-icon-close {
        color: darken(#fecb55, 10%);
      }
    }

    /*error*/
    &.p-toast-message-error {
      background: #ffe2ea;
      border: solid #ff004d;
      border-width: 0 0 0 6px;
      color: darken(#ff004d, 10%);
      .p-toast-message-icon,
      .p-toast-icon-close {
        color: darken(#ff004d, 10%);
      }
    }

  }
}

.p-toast .p-toast-message {
  font-size: 1.2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 20px;
}
