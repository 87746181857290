.agenda {
  height: calc(100dvh - (#{$headerHeight} + ( #{$layoutPaddingY} * 2)));
  padding-bottom: calc(#{$headerHeight} + ( #{$layoutPaddingY} * 4));
  //padding: 0 20px;
  //height: 100%

  &__planning {
    overflow: scroll;
    height: 100%;
  }

  &__calendar,
  &__planning > div {
    height: 100%;
  }

  /*Colonne*/
  &__planning__calendar {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 100px;
    &__fixed {
      overflow-y: auto;
      height: 100%;
      padding: 10px;
    }
    &__filters {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      .p-field-checkbox {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
      }
    }
    .time-selector-container {
      margin-top: auto;
      position: sticky;
      background-color: $whiteColor;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
    }
    &-container {
      background-color: $whiteColor;
      border-radius: 5px;
    }
  }
  .day {
    .agenda__planning__calendar-container {
      padding: 10px;
      .fc-scroller.fc-time-grid-container {
        overflow-y: auto;
        height: calc(100dvh - 340px) !important;
      }
    }
    .agenda__planning__calendar-details.with-border {
      padding: 10px;
    }

    .agenda__planning__top-info {
      padding: 0;
      .agenda__planning__title {
        position: relative;
        font-size: 2rem;
        font-weight: bold;
        color: #555555;
        border-radius: 0;
      }
    }

    .agenda__planning__bottom-info {
      margin-top: auto;
      position: sticky;
      background-color: $whiteColor;
      bottom: 0;
      left: 0;
      right: 0;

      z-index: 3;
    }
    .agenda__planning__timeslot-switch-container {
      border-top: 1px solid $veryLightGrayColor;
      border-bottom: 1px solid $veryLightGrayColor;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
    .time-selector-container {
      padding: 10px;
    }
  }

  &__planning {
    &__top-info {
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 20px 0px;
    }
    &__search {
      padding: 10px;
    }
    &__calendar-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p-button:first-child {
        margin-right: 10px;
      }
      &.with-border {
        border-bottom: 1px solid $lightBorderColor;
      }
    }

    &__calendar-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      /*
      a + a {
        margin-left: 20px;
      }

      .inputswitch__custom{
        margin-right: 20px;
        margin-left: 10px;
        top: 4px;
        position: relative;
      }
      */
      p-selectbutton {
        margin-left: 20px;
      }
      .link-green {
        margin-left: 20px;
      }
    }

    &__title {
      color: $darkGrayColor;
      font-size: 1.6rem;
      margin-left: 20px;
      font-weight: lighter;
      flex-grow: 1;
    }
  }
}

/*Vue datepicker Agenda
.agenda__calendar__fixed{
  .p-calendar {
    .p-datepicker {
      border: none;
      padding: 0;
      .p-datepicker-group-container {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 50px;

        .p-datepicker-header {
          position: relative;
          font-size: 2rem;
          font-weight: bold;
          color: $darkGrayColor;
          border-radius: 0;
          padding: 0;
          margin-bottom: 20px;
          .p-datepicker-prev:focus,
          .p-datepicker-next:focus{
            box-shadow: none;
          }
          .p-datepicker-title {
            margin: 0;
            line-height: normal;
          }

          .p-link {
            position: absolute;
            color: $primaryGreenColor;
            font-weight: bold;

            .pi {
              font-weight: bold;
              font-size: 20px;
            }

            &.p-datepicker-prev {
              right: 2rem;
            }

            &.p-datepicker-next {
              right: 0;
            }
          }
        }

        .p-datepicker-calendar {
          margin: 0;
        }
      }

      table {
        thead {
          border-bottom: 1px solid $veryLightGrayColor;
        }
        th span {
          font-size: 1.5rem;
          text-transform: uppercase;
        }

        td {
          padding: 0;
          span {
            border-radius: 20px;
            font-size: 1.6rem;
            margin:4px;
          }
        }
      }
    }
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
  .p-datepicker table td > span.p-highlight{
    background-color: rgba($primaryGreenColor, 0.4);
    color: $whiteColor;
  }
  .p-datepicker table td.p-datepicker-today > span{
    background-color: $primaryGreenColor;
    color: $whiteColor;
  }
  .p-datepicker table td > span:focus{
    box-shadow: none;
  }

}
*/
