.card__agenda{
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 3px;
    overflow: hidden;
    border-left:6px solid $purpleColor ;
    font-size: 1.4rem;
    position: relative;
    color: $darkGrayColor;
    display: flex;
    height: calc(100% - 6px);
    justify-content: space-between;
    div{
        position: relative;
        z-index: 2;
    }
    &__data{
        display: flex;
        flex-direction: column;
    }
    .bkg{
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.15;
        z-index: 0;
    }
    &__icon{
      width: 55px;
      text-align: right;
     i{
        margin-left: 5px;
      }

    }
    .button__pink{
      position: absolute !important;
      bottom: 5px;
      right: 0px;
      height: 25px;
      width: 25px;
      padding: 0;
      text-align: center;
      line-height: 25px;
      i{
        margin:0;
        font-size: 15px;

      }
    }

    &__data{
      width: calc(100% - 55px);
      > div{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  app-consultation-week-event{
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
