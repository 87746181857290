.install-pwa-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  position: absolute;
  top:0;
  overflow: hidden;
  background-color: rgba(163, 221, 134, 0.71);
  animation: 1s ease-out 0s 1 slideInTop;
  .pi-times{
    font-size: 3rem;
    cursor: pointer;
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
