
.p-overlay{
    border-radius: 5px;
    color: $darkGrayColor;
    border: 1px solid $borderPurpleColor;
    &::before{
        border-bottom-color:$borderPurpleColor;
    }
}


.overlaypanel__custom {
    .p-overlaypanel-content {
        padding: 0;
    }
   &.overlaypanel__header{
     button{
       text-align: left;
     }
     .overlaypanel__custom button:enabled:hover {
      background-color: #F6F6F6;
      border-bottom: 1px solid #E5E8F2;
      color: #555555;
  }
   }
    button {
        padding: 10px;
        background: transparent;
        color: $darkGrayColor;
        border: none;
        border-radius: 0;
        width: 100%;
        font-size: 1.4rem;

        &:enabled:hover {
            background-color: $lightGrayColor;
            border-bottom: 1px solid $borderPurpleColor;
            color: $darkGrayColor;
        }
        &:focus{
            box-shadow: none;
        }
    }
}
