p-inputSwitch {
  .p-inputswitch {
    vertical-align: middle;
    &.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    }
    &.p-inputswitch-checked {
      .p-inputswitch-slider,
      &:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: $lightBleuGreen;
      }
    }
    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        background-color: $darkBlueGreenColor;
      }
    }
  }
}

label + p-inputSwitch {
  margin-left: 20px;
}
