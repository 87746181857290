.p-dialog {
  font-size: 1.6rem;
  border: none;
  min-width: 60vw;
  max-width: 80vw;
  border-radius: 5px;
  overflow: hidden;


  .p-dialog-content {
    color: $darkGrayColor;
    padding: 20px;
    font-size: 1.4rem;
    overflow: auto;

    p{
      margin-bottom: 20px;
    }
    .agenda__planning__top-info{
      padding: 0;
    }
    .p-dialog-footer{
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -20px;
    }

  }
  .p-dialog-footer {
    border-top: 1px solid $lightBorderColor;
    background: $whiteColor;
    color: $darkGrayColor;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    .p-button.button__primary,
    .p-button.button__secondary{
      height: 30px;
      font-size: 1.4rem;
      margin-left: 20px;
    }


    .p-button{
      background-image: linear-gradient(
        90deg,
        rgba(8, 154, 169, 1) 0%,
        rgba(66, 180, 126, 1) 35%,
        rgba(126, 207, 82, 1) 100%
      );
      margin-left: 20px;


      color: $whiteColor;
      font-weight: normal;
      padding-left: 20px;
      padding-right: 20px;
      text-decoration: none;
      border-radius: 35px;
      transition: 0.5s;
      background-size: 200% auto;
      box-shadow: 0 0 6px #73c5cf;
      border: none;
      height: 30px;
      font-size: 1.4rem;
      &:hover,
      &:active {
        text-decoration: none;
        color: $whiteColor;
        background-position: right center; /* change the direction of the change here */
        background-image: linear-gradient(
          90deg,
          rgba(8, 154, 169, 1) 0%,
          rgba(66, 180, 126, 1) 35%,
          rgba(126, 207, 82, 1) 100%
        );
        border: none;
      }

      &:focus {
        box-shadow: 0 0 6px #73c5cf;
        text-decoration: none;
        background-position: right center; /* change the direction of the change here */
      }
    }
  }
  &.p-dialog-sm{
    width: 60vw;
  }
}
