p-fileupload {
  margin: 10px 0;
  .p-fileupload .p-fileupload-buttonbar {
    background: none;
    border: none;
    padding: 0;
  }
  .p-button {
    background-color: $darkBlueGreenColor;;
    border-color: $darkBlueGreenColor;;
    height: 35px;
    &:hover,
    &:active,
    &:not(.p-disabled):hover,
    &:not(.p-disabled):active {
      background-color: lighten($darkGreenColor, 5%);
      text-decoration: none;
      border: 1px solid lighten($darkGreenColor, 5%);
      color: $whiteColor;
    }

    &:focus, &.p-fileupload-choose.p-focus {
      box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
      text-decoration: none;
    }
    &:disabled {
      background-color: $lightGrayColor;
      border: 1px solid $lightGrayColor;
    }
  }
  .p-button-icon-only {
    background: none;
    font-size: 1.6rem;
    color: $lightGrayColor ;
  }
  .p-fileupload-row {
    font-size: 1.2rem;
  }
  .p-fileupload .p-fileupload-content {
    border: none;
    padding: 20px;
    p-progressbar {
      position: relative;
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
  .p-fileupload-row > div{
    width: auto;
  }
}
