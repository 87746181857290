
.agenda-entity {
  overflow: hidden !important;
}
.event {
  color: #555;
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  &-container {
    border-left: 6px solid #53418b;
    border-radius: 5px;
  }
  &__group{
    flex: 1 1 auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      &__lastname {
        font-weight: bold;
      }
    }

    &__location {
      display:flex;
      justify-content: space-between;
      div{
        padding-right: 5px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
