a.p-button,
button.p-button {
  font-family: $font;
  &:hover,
  &:active {
    opacity: 0.8;
  }
  /*Bouton degrade*/

  &.button__primary {
    background-image: linear-gradient(
      90deg,
      rgba(8, 154, 169, 1) 0%,
      rgba(66, 180, 126, 1) 35%,
      rgba(126, 207, 82, 1) 100%
    );
    color: $whiteColor;
    font-size: 1.6rem;
    font-weight: normal;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    height: 35px;
    border-radius: 35px;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 6px #73c5cf;
    border: none;

    &:hover,
    &:active {
      text-decoration: none;
      color: $whiteColor;
      background-position: right center; /* change the direction of the change here */
      background-image: linear-gradient(
        90deg,
        rgba(8, 154, 169, 1) 0%,
        rgba(66, 180, 126, 1) 35%,
        rgba(126, 207, 82, 1) 100%
      );
      border: none;
    }

    &:focus {
      box-shadow: 0 0 6px #73c5cf;
      text-decoration: none;
      background-position: right center; /* change the direction of the change here */
    }
    &:disabled {
      background-color: $grayPurpleColor;
      border: 1px solid $grayPurpleColor;
    }
    &.with-icon {
      min-height: 35px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 5px 20px;
      height: auto;
      .p-button-icon {
        font-size: 15px;
      }
      .icon-note {
        margin-left: 15px;
        margin-right: 5px;
      }

      @media (max-width: 1400px) {
        font-size: 1.2rem;
        i {
          font-size: 20px;
        }
      }
    }
    &.with-mini-icon {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      min-height: 50px;
      font-size: 1.4rem;
      &:hover {
        color: $whiteColor;
      }
      i {
        font-size: 18px;
        + i {
          margin-left: 5px;
        }
      }
    }
  }

  /*Bouton bordure grise*/

  &.button__secondary {
    background-color: $whiteColor;
    border: 1px solid $darkGrayColor;
    font-size: 1.6rem;
    color: $darkGrayColor;
    font-weight: normal;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;
    border-radius: 35px;
    text-align: center;

    &:hover,
    &:active,
    &:enabled:hover {
      background-color: darken($whiteColor, 5%);
      border: 1px solid $darkGrayColor;
      color: $darkGrayColor;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $lightGrayColor;
    }
    &:disabled {
      opacity: 0.5;

    }
  }
  &.button__icon {
    background-color: $whiteColor;
    border: 1px solid $grayColor;
    font-size: 1.8rem;
    color: $grayColor;
    font-weight: bold;
    border-radius: 40px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: $darkBlueGreenColor;
      border: 1px solid $darkBlueGreenColor;
      color: $whiteColor;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $darkBlueGreenColor;
    }
    &:disabled {
      opacity: 0.5;
      &:hover,
      &:active {
        background-color: $whiteColor;
        border: 1px solid $grayColor;
      color: $grayColor;
      cursor: not-allowed;

      }
    }
    .p-button-icon {
      position: relative;
      left: 2px;
      top: -1px;
    }
    &.button__note {
      font-size: 10px;
      .icon-note {
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
        font-size: 24px;
      }
    }
  }
  /*petit bouton plus*/
  &.button__add {
    padding: 0;
    background: none;
    display: block;
    border: none;
    text-align: left;
    margin-bottom: 20px;
    overflow: unset;
    font-size: 1.4rem;
    color: $lightdarkGrayColor;
    i {
      color: $lightdarkGrayColor;
      display: inline-block;
      vertical-align: middle;
      opacity: 1;
      transition: all ease 0.2s;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      border-radius: 25px;
      border: 1px solid $lightdarkGrayColor;
      line-height: 22.5px;
      text-align: center;
      background-color: $whiteColor;
    }

    &:focus {
      border: none;
    }
  }

  &.button__iconadd {
    font-size: 1.6rem;
    background: none;
    border: none;
    font-weight: 600;
    padding: 0 10px;
    color: $darkGrayColor;
    display: block;
    margin: 0 auto;
    opacity: 1;
    transition: all ease 0.2s;
    .p-button-icon {
      color: $darkBlueGreenColor;
      font-size: 40px;
      vertical-align: middle;
      margin-left: 0;
      margin-right: 10px;
    }
    &:enabled:hover {
      background: none;
      color: $darkGrayColor;
      border: none;
      opacity: 0.8;
    }
    &:focus {
      box-shadow: none;
    }
  }

  /*Bouton action vert*/

  &.button__transparent-green {
    background-color: transparent;
    color: $darkBlueGreenColor;
    border: none;
    span {
      font-size: 15px;
    }
    &:hover,
    &:active {
      color: $darkBlueGreenColor;
      background-color: transparent;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
.button__icon{
    cursor: pointer;
    &:focus {
        border: none;
        box-shadow: none;
    }

    &.width20 {
      width: 20px;
      text-align: center;
    }
}
/*bouton rose dans le calendrier*/
.button__pink {
  z-index: 1000000;
  cursor: pointer;
  border-radius: 30px;
  color: $pink;
  background-color: $whiteColor;
  padding: 0 5px;
  font-size: 1.2rem;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 10px;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.button__pink__icon {
  cursor: pointer;
  border-radius: 50%;
  color: $pink;
  background-color: $whiteColor;
  font-size: 1.2rem;
  height: 20px;
  width: 20px;
  text-align: center;
  i{
      font-size: 12px;
      vertical-align: middle;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.suggestionMediaBox {
  .deleteBox {
    float: right;
  }
}


.button--light{
  background-color: transparent;
  cursor: pointer;
  color: $lightdarkGrayColor;
  i{
    margin-left: 5px;
    font-size: 10px;
  }
}

button.p-button.button__big{
  justify-content: center;
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
}
