.p-calendar {
  width: 100%;
}


.p-datepicker-month,
.p-datepicker-year{
  width: auto;
}

.p-datepicker table td > span.p-highlight{
  background-color: $primaryGreenColor;
  color: $whiteColor;
}
.p-datepicker table td > span{
  border-radius: 50%;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover{
  color:$primaryGreenColor;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus{
  box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
}
/*Calendar with bouton*/
.p-calendar-w-btn{
  border-radius: 5px;
  border: solid 1px $lightLiGrayGrayColor;

  &:not(.p-disabled).p-focus, &:focus, &:enabled:focus{
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    border: solid 1px $lightLiGrayGrayColor;
  }
  &:not(.p-disabled):hover, &:hover, &:enabled:hover{
    border: solid 1px darken($lightLiGrayGrayColor, 10%);
  }


  .p-inputtext{
    border:none !important;
  }

  input{
    border: none;
    &:hover,
    &:enabled:hover{
      border: none;
    }
    &:focus,
    &:enabled:focus{
      box-shadow: none;
    }
  }
  .p-button{
    background: none;
    color: $darkGrayColor;
    padding-right: 10px;
    width: auto;
    padding-left: 5px;
    &:enabled:hover,&:enabled:active{
      background: none;
      border: none;
      color: $darkGrayColor;
    }
    &:focus{
      box-shadow: none;
    }
  }
}
