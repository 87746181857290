.p-dropdown, .p-dropdown-panel{
  outline: none;
  box-shadow: none;
  background: $whiteColor;
  border-radius: 5px;
  resize: vertical;
  width: 100%;
  font-size: 1.4rem;
  line-height: 28px;
  text-align: left;
  border: solid 1px $lightLiGrayGrayColor;

  &:not(.p-disabled).p-focus, &:focus, &:enabled:focus{
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    border: solid 1px $lightLiGrayGrayColor;
  }
  &:not(.p-disabled):hover, &:hover, &:enabled:hover{
    border: solid 1px darken($lightLiGrayGrayColor, 10%);
  }

  .p-dropdown-header{
    padding: 4px 8px;
  }

  .p-dropdown-label,
  .p-dropdown-panel{
    font-size: 1.4rem;
  }
  &.p-dropdown-clearable .p-dropdown-label,
  &.p-dropdown-clearable .p-dropdown-label:hover,
  .p-dropdown-label,
  .p-dropdown-label:hover{
    border: none !important;
  }
  .p-dropdown-label{
  padding: 4px 10px;
  }
  .p-dropdown-item{
  padding: 4px 10px;
  }
  .p-dropdown-clear-icon{
    right: 60px;
  }
  &.p-dropdown-clearable .p-dropdown-label{
    padding-right: 25px;
  }

}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  text-align: left;
  &.p-highlight{
    background-color:rgba($darkBlueGreenColor,0.2);
    color: $darkGrayColor;
  }

}
.p-dropdown .p-dropdown-trigger{
  background-color: $lightBleuGreen;
  width: 45px;
  color: $darkBlueGreenColor;
}
