wvn-p-inputNumber,p-inputnumber {
  button.p-inputnumber-button {
    background-color: transparent;
    border-color: transparent;
    color: $purpleColor;
    &:hover, &:focus, &:active{
      color: $purpleColor;
    }
    &:enabled:hover {
      background-color: transparent;
      border-color: transparent;
      color: $purpleColor;
    }
  }

  input.p-inputnumber-input:enabled:focus {
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
  }
  .p-inputnumber-button-group{
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  .p-inputnumber-buttons-stacked{
    position: relative;
    .p-inputnumber-input {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
    .p-inputnumber-button-group .p-button.p-inputnumber-button{
      background-color: transparent;
      padding: 0;
      height: 20px;
    }
  }
}

span.p-inputnumber {
  display: flex;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
  background-color: $purpleColor;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input:disabled {
  opacity: 0.5;
  border-radius: 5px;

  + .p-inputnumber-button-group {
    display: none;
  }
}
