
  .icon-extinction {
    color: #74e8da;
    background-color: #b3fdfa;
  }
  .icon-sleep {
    color: #ffffff;
    background-color: #072858;
  }
  .icon-insomnia {
    background-color: #fff79a;
    color: #b86817;
  }
  .icon-wakeup {
    background-color: #ffd9f1;
    color: #fb9dc3;
  }
  .icon-tired {
    background-color: #ff815a;
    color: #cd4c2a;
  }
  .icon-sleeping {
    background-color: #eafda3;
    color: #5d9203;
  }
  .icon-si {
    color: #ffffff;
    background-color: #f54a4a;
  }
  .icon-sv {
    color: #ffffff;
    background-color: #54b5e5;
  }
  .icon-erase {
    color: #858585;
    background-color: #ffffff;
  }


.sleep-diary--select {
  margin-left: -5px;
  display: flex;
  margin-top: 20px;
  .p-selectbutton.p-buttonset .p-button {
    background-color: transparent;
    height: auto;
    padding: 0;
    width: auto;
    border: none;
    margin-right: 10px;

    span {
      padding: 5px;
    }
    i {
      height: 100%;
      width: 100%;
      display: block;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      line-height: 35px;
      text-align: center;
    }
    .icon-erase:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      border: 1px solid #858585;
      height: 35px;
      width: 35px;
      border-radius: 50%;
    }
    .icon-sleeping:before {
      position: relative;
      left: 0px;
      top: -2px;
      font-size: 14px;
    }
  }
  .p-highlight {
    i:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      border: 1px solid #888888;
      height: 37px;
      width: 37px;
      border-radius: 50%;
    }
  }
}

.sleep-diary--table {
  margin-top: 20px;
  display: block;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 50px;
  overflow-y: auto;
  input,
  select,
  textarea,
  input.p-inputtext,
  .p-inputtext {
    height: 30px;
  }

  wvn-p-inputNumber
    .p-inputnumber-buttons-stacked
    .p-inputnumber-button-group
    .p-button.p-inputnumber-button,
  p-inputnumber
    .p-inputnumber-buttons-stacked
    .p-inputnumber-button-group
    .p-button.p-inputnumber-button{
      height: 14px;
    }
    .p-datatable
    .p-datatable-thead
    > tr
    > th {
    background: none;
    border: none;
    color: $darkGrayColor;
    font-weight: normal;
    background-color: $lightLightGrayGrayColor;
    font-size: 1.2rem;
    padding: 10px;
    vertical-align: bottom;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    padding: 0;
  }

  .p-datatable .p-datatable-tbody > tr > td.date,
  .p-datatable .p-datatable-thead > tr > th.date {
    border: none;
    font-size: 1.2rem;
    background-color: $lightLightGrayGrayColor;
    text-transform: capitalize;
    padding: 10px;
  }
  .p-datatable .p-datatable-thead > tr > th.hour {
    border-left: 1px solid #e1e1e1;
  }
  .p-datatable .p-datatable-thead > tr > th.hypno {
    font-size: 2rem;
    padding: 10px;
  }

  .p-datatable .p-datatable-thead > tr > th.quality {
    text-align: center;
    white-space: nowrap;
    border-left: 1px solid #e1e1e1;
  }

  .p-datatable .p-datatable-tbody > tr:first-child td {
    border-top: 1px solid #e1e1e1;
    &.date {
      border: none;
    }
  }
  .p-datatable .p-datatable-tbody > tr:last-child td {
    border-bottom: 1px solid #e1e1e1;
    &.date {
      border: none;
    }
  }
  .p-datatable .p-datatable-tbody > tr > td.picto {
    height: 100%;
    position: relative;
    min-width: 35px;
    cursor: cell;
    i {
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:before {
        position: relative;
        top: 10px;
        font-size: 20px;
      }
    }
  }
  .p-datatable .p-datatable-tbody > tr > td.padding {
    padding: 5px;
    text-align: center;
  }
}

.options-legend {
  .title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .list-picto {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-size: 1.4rem;
    .icon {
      margin-right: 15px;
      > i {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        display: block;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}

.sleep-diary-stats {
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .sleep-diary-stat {
    border: 1px solid #cfecec;
    background-color: $lightlightBleuGreen;
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    height: 101px;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    margin: 0px;
    &:nth-child(2) {
      margin: 0 30px;
    }
    .statName {
      margin-bottom: 7px;
      color: $lightdarkGrayColor;
    }

    .statValue {
      font-size: 24px;
      color: $darkBlueGreenColor;
    }
  }
}
