.dialog-actions {
  display: flex;
  > *{
    margin-left: 10px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
 }
 .group--title {
  flex: 1 1 auto;
  .abovetitle, .subtitle{
    font-size: 1.2rem;
    color: $grayColor;
  }
  .title{
    font-size: 1.6rem;
    font-weight: normal;
    a{
      color: $darkBlueGreenColor;
      margin-left: 5px;
      i{
        position: relative;
        top: -2px;
      }
    }
  }
  .small {
    font-style: italic;
    font-weight: normal;
    font-size: 1.2rem;
    margin-left: 5px;
    display: inline-block;
  }
}

.p-dialog .p-dialog-header, .p-sidebar .p-sidebar--header, .detached_content--header {
  border-bottom: 1px solid $lightBorderColor;
  background: $whiteColor;
  color: $darkGrayColor;
  padding: 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: flex-end;

}
.p-sidebar--header{
  border-radius: 0;
}


.p-sidebar ,.p-dialog .p-dialog-content, .detached_content--content  {
  h2{

    font-size: 1.6rem;
    color: $lightdarkGrayColor;
    border-bottom: 1px solid $lightLiGrayGrayColor;
    padding-bottom: 2px;
  }
  h3{
    font-size: 1.4rem;
    color: $lightdarkGrayColor;
    margin-bottom: 10px;

    &.intention-title {
    margin-top: 20px;
    &:first-child{
      margin-top: 0;
    }
    .tuile--simple:last-child{
      border-bottom: none;
    }
    }
  }
  .wyswig{
    margin-bottom: 20px;
    margin-top: 20px;
  }


  h3 + .instruction{
    padding-left: 0;
    padding-right: 0;
  }

  .answer + h3,   .answer + h2{
    margin-top: 20px;
  }

  p-accordion + h2{
    margin-top: 30px;
  }

  .banner {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
    width: 100%;
    display: block;
  }
}
