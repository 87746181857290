
h1{
  font-size: 2rem;
  margin-bottom: 30px;
}

h2{
  font-size: 2.0rem;
  color: $darkBlueGreenColor;
  margin-bottom: 30px;
  margin-top: 0;
}
h4{
  font-size: 1.6rem;
  margin-bottom: 30px;
}
a{
  cursor: pointer;
  text-decoration: none;
  &:hover, &:active, &:focus, &:visited{
    text-decoration: none;
    color: $darkBlueGreenColor;
  }
  &.link{
    color: $darkBlueGreenColor;

  }
}

a.link-underline{
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.4rem;
  &:hover, &:active, &:focus, &:visited{
    text-decoration: none;
    color: $darkBlueGreenColor;
  }
  i{
    color: $primaryGreenColor;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}

a.link-green{
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  color: $darkGrayColor;
  transition: all 0.3 ease;
  &:hover, &:active, &:focus, &:visited{
    text-decoration: none;
    color: $darkBlueGreenColor;
  }
  i{
    background-color: $primaryGreenColor;
    color:  $whiteColor;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
  }
}

strong, b{
  font-weight: 600;
}
em, i{
  font-style: oblique;
  margin-right: 5px;
}
