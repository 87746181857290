
.alert{
  background-color: rgba($darkBlueGreenColor,0.15);
  color: $darkBlueGreenColor;
  border: solid 1px rgba($darkBlueGreenColor,0.35);
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;

  .icon{
    padding: 10px;
    i{
      font-size: 2.5rem;
      color: $darkBlueGreenColor;
    }
  }
  span{
    vertical-align: middle;
    img{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width: 20px;
    + img{
      margin-left: 5px;
    }
    }

  }

  h4 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: initial;
    margin-left: 12.5px;
    margin-bottom: 10px;
  }

  .link{
    text-decoration: underline;
    cursor: pointer;
  }

  &.alert-warning {
    background-color: rgba($darkOrangeColor,0.15);
    color: $darkOrangeColor;
    border: solid 1px rgba($darkOrangeColor,0.35);
    a {
      text-decoration: underline;
      color: darken($darkOrangeColor, 10%);

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: $darkOrangeColor;
      }
    }
  }

  &.alert-info{
  background-color: rgba($darkBlueGreenColor,0.15);
  color: $darkBlueGreenColor;
  border: solid 1px rgba($darkBlueGreenColor,0.35);
    a {
      text-decoration: underline;
      color: darken($darkBlueGreenColor, 10%);

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: $darkBlueGreenColor;
      }
    }
    .icon{
      i{
        color: $darkBlueGreenColor;
      }
    }
  }
}

.safari-install-advice {
  display: none;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: $whiteColor;
  z-index: 10;
  width: 90%;
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0,0,0, 0.16);
  text-align: center;
  animation: fade 0.5s;

  &--open {
    display: block;
  }

  &__close {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $whiteColor;
    color: $grayColor;
    top: 0;
    right: 0;
    border: 1px solid $lightGrayColor;
    transform: translate(50%, -50%);
    font-size: 1.2rem;
  }

  .icon-share-apple {
    color: $recreativeColor;
    font-size: 2.4rem;
    vertical-align: bottom;
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid $whiteColor;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
}
