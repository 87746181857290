

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $lightBleuGreen $lightlightBleuGreen;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: $lightlightBleuGreen;
}

*::-webkit-scrollbar-thumb {
  background-color:$lightBleuGreen;
  border-radius: 15px;
}
