html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-size: 1.3rem;
  line-height: 20px;
  color: $darkGrayColor;
  height: 100dvh;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.p-component {
  font-family: $font;
  font-size: 1.6rem;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

*::selection {
  background-color: rgba($darkBlueGreenColor, 0.75);
  color: $whiteColor;
}

.layout {
  &__header {
    padding-bottom: $layoutPaddingX;
    header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .action i{
      margin-left: 1rem;
    }
  }

  &__main {
    display: block;
    height: auto;
  }
  &__wrapper {
    height: 100dvh;
    z-index: 998;
    overflow: hidden;
    padding: $layoutPaddingY $layoutPaddingX;
  }

  &__wrapper::-webkit-scrollbar {
    display: none;
  }

}

