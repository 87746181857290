.p-tabview {
  .p-tabview-nav {
    padding: 0 10px;
    flex: 0;
    background-color: $whiteColor;
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    position: relative;
    z-index: 3;
    li {
      margin-right: 20px;

      &.p-highlight .p-tabview-nav-link,
      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        border-color: $purpleColor;
        background-color: transparent;
        color: $darkGrayColor;
        &:focus {
          box-shadow: none;
        }
        &:after {
          height: 3px;
          width: 100%;
          position: absolute;
          content: "";
          border-radius: 5px;
          bottom: 0px;
          left: 0;
          background-color: $purpleColor;
        }
      }

      &.p-highlight .p-tabview-nav-link {
        font-weight: bold;

        &:focus,
        &:active {
          box-shadow: none !important;
        }
      }

      .p-tabview-nav-link {
        background: transparent;
        border: none;
        padding:10px;
        color: $darkGrayColor;
        font-weight: lighter;
        font-size: 1.8rem;
        position: relative;

        .p-tabview-title {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .p-tabview-panels{
    border: none;
    padding: 30px;
    border-radius: 0;
    font-size: 1.5rem;
    border-radius:5px;
    h4{
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 10px;
      &.mt{
        margin-top: 40px;
      }
    }
    .empty--topalign{
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .p-field{
      margin-bottom: 40px;
    }
  }
}
