.p-checkbox + label,
.custom__checkbox + label {
  margin: 0;
  vertical-align: middle;
  padding-left: 10px;
  cursor: pointer;
}
.date_consult{
  margin-top: 5px;
  padding-left: 25px;
  font-style: italic;
}

.p-checkbox + .box {
  margin-left: 10px;
  height: 16px;
  width: 16px;
  + label {
    margin-bottom: 0;
  }
}

.p-checkbox-box{
  border: 1px solid $grayColor;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  box-shadow: 0 0 6px 0.2rem rgba($primaryGreenColor, 0.5);
  border-color: $primaryGreenColor;
}

.p-checkbox .p-checkbox-box, .p-checkbox {
  height: 16px;
  width: 16px;
  border-radius: 5px;
  .p-checkbox-icon{
    font-size: 11px;
  }
}
p-checkbox {
  background: transparent !important;
  .p-checkbox-box.p-highlight {
    background: transparent;
    background-color: $primaryGreenColor;
    border-color: $primaryGreenColor;
    border-radius: 5px;
    &:not(.p-disabled):hover {
      background-color:$primaryGreenColor;
      border-color: $primaryGreenColor;
      opacity: 0.8;
    }
  }

  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 6px 0.2rem rgba($primaryGreenColor, 0.5);
    border-color: transparent;
  }
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background-color: $primaryGreenColor;
  border-color:$primaryGreenColor;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: $primaryGreenColor;
  border-color: $primaryGreenColor;

}
