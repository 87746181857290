.p-tooltip{
  font-size:1.2rem;
  max-width: 250px;
  .p-tooltip-text{
    background-color: $whiteColor;
    color: $darkGrayColor;
    box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.25);
  }
  &.p-tooltip-right .p-tooltip-arrow{
    border-right-color:$whiteColor !important;
  }
  &.p-tooltip-top .p-tooltip-arrow{
    border-top-color:$whiteColor !important;
  }
  &.p-tooltip-left .p-tooltip-arrow{
    border-left-color:$whiteColor !important;
  }
  &.p-tooltip-bottom .p-tooltip-arrow{
    border-bottom-color:$whiteColor !important;
  }
}
