p-multiselect{
  font-size: 1.4rem;
}
.p-multiselect.p-multiselect-chip{
  padding-left: 3px;
}
.p-multiselect{
  outline: none;
  box-shadow: none;
  background: $whiteColor;
  border: solid 1px $lightLiGrayGrayColor;
  border-radius: 5px;
  min-height: 40px;
  resize: vertical;
  width: 100%;
  font-size: 1.4rem;
  line-height: 28px;
  text-align: left;
  padding: 0 0 0 10px;

  .p-multiselect-header{
    padding: 4px 8px;
  }

  .p-multiselect-items{
    .p-checkbox{
      margin: 0 10px;
    }
  }

  &:focus, &:enabled:focus{
    box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
    border: solid 1px $lightLiGrayGrayColor;
  }
  &:not(.p-disabled):hover, &:hover, &:enabled:hover{
    border: solid 1px darken($lightLiGrayGrayColor, 10%);
  }

  &-chip .p-multiselect-label{
    white-space: normal;

  }
  .p-multiselect-label-container{
    min-height: 40px;
    padding: 0px;
    width: calc(100% - 52px);
    .p-multiselect-label{
      padding: 0px;
    }

  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    background-color: $lightLiGrayGrayColor;
    font-size: 1.4rem;
    color: $darkGrayColor;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    font-size: 1.4rem;
    color: $darkGrayColor;
  }

  .p-multiselect-trigger{
    background-color: $lightBleuGreen;
    width: 45px;
    color: $darkBlueGreenColor;
  }
}
.p-multiselect:not(.p-disabled).p-focus{
  box-shadow: 0 0 6px .2rem rgba($primaryGreenColor, 0.5);
  border: solid 1px $grayColor;
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{
  box-shadow: none;
}

/*Chips*/

.p-multiselect.p-multiselect-chip .p-multiselect-token{
  border-radius: 3px;
  padding: 0 5px;
  background-color: #CFECEC;
  margin-right: 7px;
  margin-top: 4px;
  margin-bottom: 3px;

  .p-multiselect-token-label{
    font-size: 1.4rem;
    color: $darkGrayColor;
  }
  .p-multiselect-token-icon{
    margin: 0 5px 0 10px;
    color: #3AAEB9;
  }
  .p-multiselect-token-icon:before{
    content:"\e917";
    font-family: 'siinapppro' !important;
  }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  background-color: $lightLightGrayGrayColor;
  color: $darkGrayColor;
}
